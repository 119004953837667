<template>
  <div v-if="currentUser.superAdmin">
    <div class="field-wrapper p-4 m-6">
      <div class="filter-title">Cashback Voucher Value</div>
      <p>
        This field allows you to set the value of the cashback voucher displayed on the main feed
        banner.
      </p>
      <div class="d-flex align-center">
        <p class="field-warning font-italic">
          Enter the desired value for the cashback voucher in the field provided.
        </p>
        <v-text-field
          label="Value"
          variant="outlined"
          v-model="cashbackVoucher.propertyValue"
          class="pb-1 pr-15 custom-width"
          type="number"
        >
        </v-text-field>
      </div>

      <div class="field">
        <div class="field-preview">
          GET <span class="font-weight-bold blue--text">{{ cashbackVoucher.propertyValue }}</span>
          <span v-if="cashbackVoucher.propertyValue">€</span> CASHBACK VOUCHER
        </div>

        <v-btn color="secondary" class="mr-2 my-2" @click="updateCahsback(cashbackVoucher)">
          {{ $t("common.save") }}
        </v-btn>
      </div>
    </div>

    <div class="w-300px pl-4 pb-2">
      <v-combobox
        :items="countries"
        :label="$t('commonTable.country')"
        item-text="name"
        item-value="id"
        v-model="selectedCountry"
        @change="onChangeCountry"
        outline
      ></v-combobox>
    </div>
    <v-data-table
      :headers="headers"
      :items="bannerDefinitions"
      :options.sync="pagination"
      :server-items-length="totalBannerDefinitions"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.media="{ item }">
        <a v-if="item.media != null" v-bind:href="item.media.thumbPath3" target="_blank">
          <v-img
            v-if="item != null && item.media != null && item.media.thumbPath1 != null"
            :src="item.media.thumbPath1"
            contain
            max-height="200"
            max-width="200"
            @error="item.media.thumbPath1 = 'media/error/imageNotFound.png'"
          >
          </v-img>
        </a>
      </template>
      <template v-slot:item.clear="{ item }">
        <v-avatar color="teal" size="26" rounded>
          <router-link
            :to="{
              name: 'BannerDefinitionEdit',
              params: { bannerDefinitionId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar color="red darken-4" size="26" rounded>
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveBannerDefinitions();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  clearSearch();
                "
                >mdi-close
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <router-link :to="{ name: 'BannerDefinitionCreate' }">
              <v-avatar color="teal" size="26" rounded>
                <v-icon dark>mdi-plus</v-icon>
              </v-avatar>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import CountryService from "@/core/services/country.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import { mapGetters } from "vuex";
import SettingsApiService from "@/core/services/api.service.settings";

export default {
  name: "banner-definitions",
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("menu.bannerDefinitions");
    },
    headers() {
      return [
        { text: "Id", align: "start", sortable: true, value: "id" },
        { text: this.$i18n.t("pages.bannerDefinitions.bannerType"), value: "bannerType" },
        { text: this.$i18n.t("common.image"), value: "media" },
        { text: this.$i18n.t("pages.bannerDefinitions.linkType"), value: "linkType" },
        { text: this.$i18n.t("common.link"), value: "linkValue" },
        { text: this.$i18n.t("common.text"), value: "text" },
        { text: "", value: "search", width: "5%" },
        { text: "", value: "clear", width: "5%" },
        { text: "", value: "add", width: "5%" }
      ];
    }
  },
  data() {
    return {
      cashbackVoucher: [],
      voucherValue: null,
      totalBannerDefinitions: 0,
      bannerDefinitions: [],
      defaultBannerDefinition: {
        id: null,
        bannerType: "",
        text: "",
        linkType: "",
        linkValue: "",
        media: null,
        base64Image: null,
        targetId: null
      },
      selectedBannerDefinition: {
        id: null,
        bannerType: "",
        text: "",
        linkType: "",
        linkValue: "",
        media: null,
        base64Image: null,
        targetId: null
      },
      loading: true,
      pagination: {},
      dialogDelete: false,
      itemToDelete: null,
      errors: [],
      countries: [],
      selectedCountry: null
    };
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveBannerDefinitions();
      },
      deep: true
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    this.getCountries();
    this.getSettingsGroupList();
  },
  components: {
    ConfirmDialog
  },
  methods: {
    getCountries() {
      this.loading = true;
      CountryService.getCountries()
        .then(res => {
          if (!res?.length) return;
          this.countries = res;
          const activeCountryId = CountryService.getActiveCountry();
          this.selectedCountry = this.countries.find(item => item.id === activeCountryId);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    onChangeCountry() {
      this.retrieveBannerDefinitions();
    },
    getSettingsGroupList() {
      this.loading = true;
      SettingsApiService.getSettingsGroup("MAIN_FEED_SETTINGS")
        .then(response => {
          if (!response.data) return;

          const groupData = response.data || [];
          this.cashbackVoucher = groupData.find(
            item => item.propertyName === "CASHBACK_VOUCHER_AMOUNT"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateCahsback(item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to change cashback voucher value to " + item.propertyValue + "€ ?"
        )
      ) {
        this.updateCashbackVoucher();
      }
    },
    updateCashbackVoucher() {
      const id = this.cashbackVoucher.id;
      SettingsApiService.updateSetting(id, this.cashbackVoucher);
    },
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("pages.bannerDefinitions.deleteMessage") + item.id + " ?"
        )
      ) {
        this.deleteRecord(item);
      }
    },
    deleteRecord(item) {
      this.deleteBannerDefinition(item.id);
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deleteBannerDefinition(this.itemToDelete.id);
      this.dialogDelete = false;
    },
    deleteBannerDefinition(bannerDefinitionId) {
      this.loading = true;
      ApiService.delete(`api/banner-definition/${bannerDefinitionId}`)
        .then(response => {
          this.$log.debug("Banner definition deleted: ", response);
          this.retrieveBannerDefinitions();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getRequestParams() {
      let params = {};

      if (this.selectedCountry) {
        params["countryId"] = this.selectedCountry.id;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },

    retrieveBannerDefinitions() {
      const params = this.getRequestParams();
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/banner-definition", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Banner definitions: ", response.data.content);
          this.bannerDefinitions = response.data.content;
          this.totalBannerDefinitions = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalBannerDefinitions != null ? this.totalBannerDefinitions : 0;

          this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + title }]);
          resolve();
        });
      });
    }
  }
};
</script>

<style lang="scss">
.field-wrapper {
  border: 1.5px solid #0a517c;
  border-radius: 0.8rem;
  padding: 24px 16px;

  .filter-title {
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(77, 77, 77, 0.71);
    padding-bottom: 15px;
  }
  .field-warning {
    padding: 5px;
    color: #62440b;
  }
  .custom-width {
    max-width: 150px;
    padding-left: 20px;
  }
  .field {
    display: flex;
    gap: 30px;
    align-items: center;

    &-preview {
      font-weight: 600;
      color: #4d4d4dd6;
      letter-spacing: 0.7px;
      font-size: 20px;
    }
  }
}
::v-deep .v-text-field > .v-input__control > .v-input__slot {
  border: 1px solid #071e26;
  padding: 10px;
  border-radius: 5px;
}
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}
.v-icon.v-icon {
  font-size: 24px !important;
}
tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
